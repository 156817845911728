<template>
  <div class="comment-home">
    <!-- begin:: Content -->
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">

      <!--Begin::App-->
      <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
        <b-row>
          <b-col sm="6" md="3">
            <b-overlay :show="commentBusy">
              <!--Begin:: App Aside-->
              <div class="kt-grid__item kt-app__aside--lg kt-app__aside--fit mb-3" id="kt_chat_aside">
                <!--begin::Portlet-->
                <div class="kt-portlet kt-portlet--last">
                  <div class="kt-portlet__body">
                    <!--<b-form-radio-group
                      id="is_series"
                      v-model="filter"
                      :options="options"
                      button-variant="success"
                      @change="onChangeFilter"
                      buttons />-->
                    <div class="kt-widget kt-widget--users kt-mt---20">
                      <div class="kt-scroll kt-scroll--pull" v-if="home_comments && home_comments.length">
                        <div class="kt-widget__items">
                          <div v-for="comment in home_comments" :key="comment.id" @click="onClickItem(comment.commentable_id)" :class="[comment.commentable_id === current_comment ? 'bg-light' : '']" class="kt-widget__item ">
                            <span class="kt-media">
                              <b-img :src="comment.film && comment.film.preview ? comment.film.preview : ''" rounded="" size="43" />
                            </span>
                            <div class="kt-widget__info">
                              <div class="kt-widget__section">
                                <a href="#" class="kt-widget__username">{{ comment.film && comment.film.title ? comment.film.title : '' }}</a>
                              </div>
                              <span class="kt-widget__desc">{{ comment.film && comment.film.region ? comment.film.region.title : 'نامەلۇم' }} / {{ comment.film && comment.film.language ? comment.film.language.title : 'نامەلۇم' }}</span>
                            </div>
                            <div class="kt-widget__action">
                              <span class="kt-badge kt-badge--success kt-font-bold" v-if="comment.comment_no_approved_counts">{{ comment.comment_no_approved_counts }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="kt-scroll kt-scroll--pull text-center" v-else>ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق ئىكەن.</div>
                    </div>
                  </div>
                </div>
                <!--end::Portlet-->
              </div>
              <!--End:: App Aside-->
              <pagination :meta="home_comments_meta" :implementSearch="false" @refresh="onRefresh" @change-page="onChangePage" />
            </b-overlay>
          </b-col>
          <b-col sm="6" md="9">
            <transition name="fade-in-up">
              <router-view></router-view>
            </transition>
          </b-col>
        </b-row>
      </div>
      <!--End::App-->
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import pagination from "@v@/components/paginate";
  import commentMixin from "@m@/comment.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { mapGetters } from "vuex";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  export default {
    name: "index",
    components: {Portlet, pagination},
    mixins: [commentMixin, formBusyMixin],
    computed: {
      ...mapGetters(['home_comments', 'home_comments_meta', 'comments_error'])
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'ئىنكاس باشقۇرۇش', route: {name: 'comment.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق ئىنكاسلار'});
    },
    data(){
      return {
        commentBusy: false,
        options: [
          { text: 'ھەممىسى', value: 1 },
          { text: 'تەستىقلانمىغىنى', value: 0 },
        ],
        filter: 1,
        current_comment: 0
      };
    },
    created() {
      this.getHomeComments();
    },
    methods: {
      onClickItem(id){
        let { id: idForParams } = this.$route.params;
        if( id !== idForParams ){
          this.$router.replace({name: 'comment.list', params: {id}});
          this.current_comment = id;
        }
      },
      onChangePage(e){
        let { page } = e;
        this.getHomeComments(page, this.filter);
      },
      onChangeFilter(v){
        this.current_comment = 0;
        this.getHomeComments(1, v)
          .then(()=>{
            if( this.$route.name !== 'comment.home')
              this.$router.replace({name: 'comment.home'});
          })
      },
      onRefresh(){
        this.getHomeComments(1, this.filter);
      },
    }
  }
</script>

<style scoped>
  .kt-grid.kt-grid--ver-desktop.kt-grid--desktop {
    display: block !important;
  }
  .kt-widget.kt-widget--users .kt-widget__item {
    padding: 1.3rem 1rem 1.3rem ;
    border-radius: 5px;
    margin: 0 0 .5rem 0;
  }

  .kt-widget.kt-widget--users .kt-widget__item:hover {
    background-color: #f8f9fa !important;
  }

  .kt-chat__pic {
    display: flex;
    justify-content: center;
  }

  .kt-widget.kt-widget--users .kt-widget__item .kt-widget__action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
