import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  DELETE_COMMENT,
  GET_COMMENT_DETAIL,
  GET_FILM_COMMENTS,
  GET_HOME_COMMENTS, REPLY_COMMENT
} from "@/store/modules/comment.module";

export default {
  methods: {
    getHomeComments(page = 1, filter = 1, actions = {
      begin: ()=>{ this.showBusy('commentBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('commentBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_HOME_COMMENTS, {page, filter}),
        actions
      );
    },
    getFilmComments(id, page = 1, actions = {
      begin: ()=>{ this.showBusy('filmCommentBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('filmCommentBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_FILM_COMMENTS, {id, page}),
      actions
      );
    },
    getCommentDetail(id, actions = {
      begin: ()=>{ this.showBusy('commentDetailBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('commentDetailBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_COMMENT_DETAIL, {id}),
      actions
      );
    },
    deleteComment(id, actions = {
      begin: ()=>{ this.showBusy('commentDetailBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('commentDetailBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
      this.$store.dispatch(DELETE_COMMENT, {id}),
      actions
      );
    },
    replyComment(id, formData = {}, actions = {
      begin: ()=>{ this.showBusy('commentDetailBusy'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.hideBusy('commentDetailBusy'); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(REPLY_COMMENT, { id, formData }),
        actions
      );
    }
  }
}
